import dotenv from "dotenv";

dotenv.config();

export default {
  SERVER: {
    URL: process.env.REACT_APP_URL,
    EVENTS: {
      CLIENT: process.env.REACT_APP_EVENTS_CLIENT,
    },
    ACTIONS: {
      CHANGE_SCREEN: "change-screen",
      EXTERNAL_REDIRECT: "external-redirect",
      GET_PRIZE: "get-prize",
      LINK_EMAIL: "link-email",
      RESTRICT_FLOW: "restrict-flow",
      START_EXPERIENCE: "start-experience",
      SUBMIT_CHOSEN_OPTION: "submit-chosen-option",
      SUBMIT_CLIENT_INFO: "submit-client-info",
      SUBMIT_PRIZE: "submit-prize",
      SUBMIT_SCREEN: "submit-screen",
    },
  },
};
