import io from "socket.io-client";

import CONFIG from "../config";

class Websocket {
  errorHandler = null;

  constructor() {
    this.socket = io(CONFIG.SERVER.URL);

    this.socket.on("connect", this.onConnect);
    this.socket.on("exception", this.onException);
    this.socket.on("disconnect", this.onDisconnect);
  }

  onConnect = () => {
    console.info("Socket Connected!");
  };

  onException = error => {
    console.error("Socket Exception =>", error);
    this.errorHandler(error);
  };

  onDisconnect = () => {
    console.error("Socket Disconnected!");
  };

  emit = (event = "", data = {}, cb = () => null) => {
    this.socket.emit(event, data, cb);
  };

  setErrorHandler = handler => {
    this.errorHandler = handler;
  };
}

export const socket = new Websocket();
