import React from "react";

import TwLogo from "./tw-logo";

import "./styles.css";

const TWServiceMessage = ({ message = [] }) => {
  return (
    <div className="tw-service-message">
      {message.map((s, i) => (
        <span key={i} className="tw-service-message__text">
          {s}
        </span>
      ))}
      <TwLogo />
    </div>
  );
};

export default TWServiceMessage;
