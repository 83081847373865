import React, { Fragment } from "react";

class Utils {
  anyWordStr = "(\\w+)";
  anyWordRegexp = new RegExp(this.anyWordStr);
  replaceTextRegex = new RegExp(`{${this.anyWordStr}}`, "g");
  componentInTextRegex = new RegExp(`{{2}${this.anyWordStr}{2}`, "g");

  collectUrlParams = () => {
    const segments = this.splitQueryOnSegments();
    const query = segments.reduce((acc, s) => {
      const parts = s.split("=");
      return { ...acc, [parts[0]]: parts[1] };
    }, {});
    const experienceId = window.location.pathname.split("/")[1];
    return { ...query, experienceId };
  };

  clearQuery = () => window.location.search.substring(1);

  splitQueryOnSegments = () => {
    const query = this.clearQuery();
    return query.split("&");
  };

  validateFormFields = (fields) => {
    const results = fields.reduce((acc, field) => {
      const type = field.dataset.type;
      const value = type === "checkbox" ? field.dataset.value === "true" : field.dataset.value;
      const error = field.dataset.error === "true";
      const required = field.dataset.required === "true";

      if (error || (required && !value)) {
        return [...acc, false];
      }

      return [...acc, true];
    }, []);

    return results.every((r) => r);
  };

  collectDataFromFields = (fields) => {
    const data = {};
    const setData = (dataset) => {
      switch (dataset.type) {
        case "checkbox":
          data[dataset.name] = dataset.value === "true";
          break;
        case "select":
          if (!(dataset.name in data)) {
            data[dataset.name] = [];
          }
          if (dataset.selected === "true") {
            data[dataset.name].push(dataset.value);
          }
          break;
        default:
          data[dataset.name] = dataset.value;
      }
    };

    fields.forEach((field) => setData(field.dataset));

    return data;
  };

  renderTextRows = (serverProps, rows, components, emitEvent) => {
    rows = this.replaceText(serverProps, rows);
    return rows.map((row, i) => (
      <div key={i} className="text-row">
        {row.match(this.componentInTextRegex)
          ? this.renderWordsOrComponents(row, components, emitEvent)
          : row}
      </div>
    ));
  };

  renderWordsOrComponents(row, components, emitEvent) {
    return (
      <Fragment>
        {row.split(" ").map((word, i) => {
          const match = word.match(this.componentInTextRegex);
          if (!match) {
            return (
              <span key={i}>
                {word}
                {i === row.length - 1 ? "" : " "}
              </span>
            );
          }

          const propName = this.findWordGroup(match[0]);
          const component = components[propName];
          const TwComponent = React.lazy(() => import(`../components/${component.clientName}`));
          return (
            <TwComponent
              key={i}
              styles={component.styles}
              {...component.props}
              emitEvent={emitEvent}
            />
          );
        })}
      </Fragment>
    );
  }

  replaceText(serverProps, text) {
    if (!serverProps) {
      return text;
    }
    return text.map((row) => {
      const match = row.match(this.replaceTextRegex);
      if (!match) {
        return row;
      }

      match.forEach((m) => {
        const propName = this.findWordGroup(m);
        row = row.replace(m, serverProps[propName]);
      });

      return row;
    });
  }

  findWordGroup(str) {
    return str.match(this.anyWordRegexp)[0];
  }
}

export const utils = new Utils();
