import React from "react";

import "./styles.css";

const TwAnimation = ({ type, indent, children }) => (
  <div className={`tw-animation animated ${type}`} style={indent}>
    {children}
  </div>
);

export default TwAnimation;
