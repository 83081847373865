import React from "react";

import { StyledTwLoader, StyledTwLoaderSpin } from "./styled";

export const TwLoader = ({ styles }) => (
  <StyledTwLoader styles={styles.twLoader}>
    <StyledTwLoaderSpin styles={styles.twLoaderSpin} />
  </StyledTwLoader>
);

export default TwLoader;
