import styled from "styled-components";

export const StyledTwBackground = styled.div`
  display: flex;
  width: ${({ styles }) => styles.width || "100%"};
  flex-direction: column;
  flex-grow: 1;
  align-items: center;
  background-size: cover;
  background: ${({ styles }) => styles.background};
  background-position-x: ${({ styles }) => styles.backgroundPositionX};
  background-position-y: ${({ styles }) => styles.backgroundPositionY};
`;
