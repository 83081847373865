import React from "react";
import ReactDOM from "react-dom";

import App from "./App";

import "./index.css";

const runApp = () => ReactDOM.render(<App />, document.getElementById("root"));

runApp();
