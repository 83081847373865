import React, { useState, Suspense } from "react";
import { ThemeProvider } from "styled-components";

import TwBackground from "./components/tw-background";
import TwAnimation from "./components/tw-animation";
import TwLoader from "./components/tw-loader";
import TwServiceMessage from "./components/tw-service-message";
import CONFIG from "./config";
import { socket, utils } from "./services";

const App = () => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  socket.setErrorHandler(setError);
  const urlParams = utils.collectUrlParams();

  const emitEvent = (options) => {
    setLoading(true);
    setError(null);

    socket.emit(
      options.event,
      { action: options.action, body: options.body, ...urlParams },
      (data) => {
        setState(data);
        setLoading(false);
      },
    );
  };

  const [state, setState] = useState({
    components: {},
    serverProps: {},
  });
  useState(() => {
    emitEvent(
      {
        event: CONFIG.SERVER.EVENTS.CLIENT,
        action: CONFIG.SERVER.ACTIONS.START_EXPERIENCE,
        body: { ...urlParams },
      },
      setState,
    );
  }, []);

  const findComponent = (list, name) => list.find(({ clientName }) => clientName === name);

  const renderLoader = (components) => {
    if (!components) return null;

    const { styles } = findComponent(components, "tw-loader");

    return <TwLoader styles={styles} />;
  };

  const renderComponents = (components) =>
    components.map(({ clientName, props, animation, indent, styles }, i) => {
      const TwComponent = React.lazy(() => import(`./components/${clientName}`));
      let childrenComponents = null;
      if (props.children) {
        childrenComponents = renderComponents(props.children);
      }

      return (
        <TwAnimation key={`name-${i}`} type={animation} indent={indent}>
          <ThemeProvider theme={{ ...styles }}>
            <TwComponent
              {...props}
              styles={{ ...styles }}
              emitEvent={emitEvent}
              serverProps={state.serverProps}
            >
              {childrenComponents}
            </TwComponent>
          </ThemeProvider>
        </TwAnimation>
      );
    });

  const renderScreen = ({ components }) => {
    if (error) {
      return <TwServiceMessage message={["Something went wrong,", "please try again later"]} />;
    }

    const isComponents = Object.keys(components).length;
    const parentComponents = components.parent;
    const childComponents = components.child;

    if (!isComponents) {
      return <TwServiceMessage message={["Wait a second..."]} />;
    }

    if (isComponents && loading) {
      return renderLoader(parentComponents);
    }

    const { styles } = findComponent(parentComponents, "tw-background");

    return (
      <ThemeProvider theme={styles}>
        <TwBackground styles={styles.twBackground}>
          <Suspense fallback={renderLoader(parentComponents)}>
            {renderComponents(childComponents)}
          </Suspense>
        </TwBackground>
      </ThemeProvider>
    );
  };

  return renderScreen(state);
};

export default App;
