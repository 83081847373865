import React from "react";

import "./styles.css";

const TwLogo = () => {
  return (
    <div className="tw-service-message__logo">
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="83.865"
        height="83.865"
        viewBox="0 0 83.865 83.865"
      >
        <g id="logo" transform="translate(-2252.556 -964.178)">
          <path
            id="Path_1368"
            data-name="Path 1368"
            d="M2284.286,972.013a46.348,46.348,0,0,1,36.437,74.955,50.771,50.771,0,0,0,4.129-3.693,46.322,46.322,0,1,0-65.509-65.508,50.869,50.869,0,0,0-3.693,4.129A44.372,44.372,0,0,1,2284.286,972.013Z"
            transform="translate(-2.019)"
            fill="#705ebc"
          />
          <path
            id="Path_1369"
            data-name="Path 1369"
            d="M2275.737,1019.385a37.371,37.371,0,0,1,29.38,60.439,40.875,40.875,0,0,0,3.33-2.978,37.351,37.351,0,1,0-52.822-52.822,40.874,40.874,0,0,0-2.978,3.329A35.778,35.778,0,0,1,2275.737,1019.385Z"
            transform="translate(-0.059 -31.908)"
            fill="#705ebc"
          />
          <path
            id="Path_1370"
            data-name="Path 1370"
            d="M2269.531,1068.972a27.474,27.474,0,0,1,21.6,44.433,30.221,30.221,0,0,0,2.448-2.189,27.459,27.459,0,1,0-38.833-38.833,30.106,30.106,0,0,0-2.189,2.448A26.306,26.306,0,0,1,2269.531,1068.972Z"
            transform="translate(0 -65.362)"
            fill="#705ebc"
          />
          <g
            id="Group_597"
            data-name="Group 597"
            transform="translate(2253.349 1016.865)"
          >
            <path
              id="Path_1371"
              data-name="Path 1371"
              d="M2285.163,1131.018a15.161,15.161,0,1,0-4.448,10.729A14.6,14.6,0,0,0,2285.163,1131.018Z"
              transform="translate(-2254.839 -1115.856)"
              fill="#705ebc"
            />
          </g>
        </g>
      </svg>
    </div>
  );
};

export default TwLogo;
