import styled from "styled-components";

export const StyledTwLoader = styled.div`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100%;
  justify-content: center;
  align-items: center;
  background-color: ${({ styles }) => styles.backgroundColor};
  z-index: 10;
`;

export const StyledTwLoaderSpin = styled.span`
  position: absolute;
  animation-name: circularRotation;
  animation-duration: 1.2s;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  background: ${({ styles }) => styles.background};
  width: ${({ styles }) => styles.width};
  height: ${({ styles }) => styles.height};

  @keyframes circularRotation {
    0% {
      transform: rotate(0deg);
    }

    100% {
      transform: rotate(360deg);
    }
  }
`;
